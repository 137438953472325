<template>
    <div>
        <div class="falling-things" aria-hidden="true" v-if="!isMobileDevice()">
            <div v-if="isWinter">
                <div class="falling-thing" :style="fallingCssVars()" v-for="i in 10" :key="'o_' + i">
                    ❅
                </div>
                <div class="falling-thing" :style="fallingCssVars()" v-for="i in 10" :key="'o_' + (i + 100)">
                    ❆
                </div>
                <div class="falling-thing" :style="fallingCssVars()" v-for="i in 3" :key="'o_' + (i + 200)">
                    <b-img :src="requireImg('seasonalBackgrounds/winter1.png')"/>
                </div>
            </div>

            <div v-else-if="isAutumn">
                <div class="falling-thing" :style="fallingCssVars()" v-for="i in 8" :key="'o_' + i">
                    <b-img :src="requireImg('seasonalBackgrounds/autumn1.png')"/>
                </div>
                <div class="falling-thing" :style="fallingCssVars()" v-for="i in 8" :key="'o_' + (i + 100)">
                    <b-img :src="requireImg('seasonalBackgrounds/autumn2.png')"/>
                </div>
                <div class="falling-thing" :style="fallingCssVars()" v-for="i in 2" :key="'o_' + (i + 200)">
                    <b-img :src="requireImg('seasonalBackgrounds/autumn3.png')"/>
                </div>
            </div>

            <div v-else-if="isSummer">
                <div class="falling-thing" :style="fallingCssVars()" v-for="i in 7" :key="'o_' + i">
                    <b-img :src="requireImg('seasonalBackgrounds/summer1.png')"/>
                </div>
                <div class="falling-thing" :style="fallingCssVars()" v-for="i in 7" :key="'o_' + (i + 100)">
                    <b-img :src="requireImg('seasonalBackgrounds/summer2.png')"/>
                </div>
                <div class="falling-thing" :style="fallingCssVars()" v-for="i in 4" :key="'o_' + (i + 200)">
                    <b-img :src="requireImg('seasonalBackgrounds/summer3.png')"/>
                </div>
            </div>

            <div v-else-if="isSpring">
                <div class="falling-thing" :style="fallingCssVars()" v-for="i in 6" :key="'o_' + i">
                    <b-img :src="requireImg('seasonalBackgrounds/spring1.png')"/>
                </div>
                <div class="falling-thing" :style="fallingCssVars()" v-for="i in 6" :key="'o_' + (i + 100)">
                    <b-img :src="requireImg('seasonalBackgrounds/spring2.png')"/>
                </div>
                <div class="falling-thing" :style="fallingCssVars()" v-for="i in 6" :key="'o_' + (i + 200)">
                    <b-img :src="requireImg('seasonalBackgrounds/spring3.png')"/>
                </div>
            </div>
        </div>

        <slot/>
    </div>
</template>

<script>
    import {seasonChecks}   from '@/util/season';
    import {requireImg}     from '@/util/resources';
    import {isMobileDevice} from '@/util/mobile';

    export default {
        name: 'SeasonalBackground',
        computed: {...seasonChecks},
        methods: {
            requireImg,
            isMobileDevice,
            randomLeft() {
                return (Math.random() * 100) + '%';
            },
            randomAnimationDelay() {
                return (Math.random() * 5) + 's';
            },
            randomXTransform() {
                return (Math.random() * 75 + 25) + 'px';
            },
            randomFallDuration() {
                return (Math.random() * 7.5 + 10) + 's';
            },
            fallingCssVars() {
                return {
                    '--left': this.randomLeft(),
                    '--animation-delay-1': this.randomAnimationDelay(),
                    '--animation-delay-2': this.randomAnimationDelay(),
                    '--x-transform': this.randomXTransform(),
                    '--fall-duration': this.randomFallDuration()
                }
            }
        }
    }
</script>

<style scoped>
    .falling-things {
        z-index: 0;
    }

    /* customizable falling-thing styling */
    .falling-thing {
        color: #fff;
        font-size: 1em;
        font-family: Arial, sans-serif;
        filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.5));

        position: fixed;
        top: -10%;
        z-index: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: default;
        -webkit-animation-name: falling-things-fall, falling-things-shake;
        -webkit-animation-duration: var(--fall-duration), 4s;
        -webkit-animation-timing-function: linear, ease-in-out;
        -webkit-animation-iteration-count: infinite, infinite;
        -webkit-animation-play-state: running, running;
        animation-name: falling-things-fall, falling-things-shake;
        animation-duration: var(--fall-duration), 4s;
        animation-timing-function: linear, ease-in-out;
        animation-iteration-count: infinite, infinite;
        animation-play-state: running, running;

        left: var(--left);
        -webkit-animation-delay: var(--animation-delay-1), var(--animation-delay-2);
        animation-delay: var(--animation-delay-1), var(--animation-delay-2);
    }

    @-webkit-keyframes falling-things-fall {
        0% {
            top: -10%;
        }
        100% {
            top: 100%;
        }
    }

    @-webkit-keyframes falling-things-shake {
        0%, 100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
        50% {
            -webkit-transform: translateX(var(--x-transform));
            transform: translateX(var(--x-transform));
        }
    }

    @keyframes falling-things-fall {
        0% {
            top: -10%;
        }
        100% {
            top: 100%;
        }
    }

    @keyframes falling-things-shake {
        0%, 100% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(var(--x-transform));
        }
    }
</style>